import React from 'react'
import { Link } from 'react-router-dom';

const FooterPre = () => {
  return (
    <>
      <div className="prefooter pt-4">
  <div className="container">
    <div className="row">
      {/* Column 1 - Width 6 */}
      <div className="col-5">
        <p className="paragraph">
          Content on this website is published and managed by Nagar Nigam
          Ayodhya, Uttar Pradesh. For any query regarding this website,
          please contact the "
          <Link to="/webInfoManager">Web Information Manager</Link>". This
          is the official website of Nagar Nigam Ayodhya, Government of
          Uttar Pradesh, India.
        </p>
      </div>

      {/* Column 2 - Width 4 */}
      <div className="col-4">
        <p>
          <b>Visitor No. - 2435206526</b>
        </p>
        <p>
          <b>
            <Link to="https://3iconsulting.in/" className="linkcolor">
              Powered by - 3i Consulting Private Limited
            </Link>
          </b>
        </p>
      </div>

      {/* Column 3 - Width 2 */}
      <div className="col-3 text-end">
        <a
          href="https://www.facebook.com/AyodhyaNgrNigam"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-brands fa-facebook socialicon"></i>
        </a>
        <a
          href="https://x.com/AyodhyaNgrNigam"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-brands fa-x-twitter socialicon"></i>
        </a>
        <a
          href="https://www.instagram.com/ayodhyanagarnigam/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-brands fa-instagram socialicon"></i>
        </a>
        <a
          href="https://www.youtube.com/@ayodhyanagarnigam"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-brands fa-youtube socialicon"></i>
        </a>
        <a
          href="https://www.linkedin.com/company/ayodhyanagarnigam/posts/?feedView=all"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-brands fa-linkedin socialicon"></i>
        </a>
      </div>
    </div>
  </div>
</div>
    </>
  );
}

export default FooterPre