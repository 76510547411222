
import React from "react";
import ImportLinks from "../../other/ImportLinks";
import Heading2 from "../../other/Heading2";
import PageHeaderImg from "../../other/PageHeaderImg";
import Footer from '../../other/Footer';
import FooterPre from '../../other/FooterPre';

const TermCondition = () => {

  return (
    <>
      <div className="container-fluid accountBGImg">
        <div className=" container breadcum">
          <Heading2 HName="Term & Condition" />
          <PageHeaderImg BreadName1="Home" BreadName2="Term & Condition" />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="row py-4">
          <div className="col-lg-9">
            <div className="card mb-3">
              <div className="card-header h4 bg-warning">Term & Condition</div>
              <div className="card-body aboutus">
              <p>Welcome to the official website of Nagar Nigam Ayodhya. This website is maintained by 3i Consulting Pvt. Ltd. &nbsp;By accessing or using this website, you agree to comply with the following terms and conditions. If you do not agree with these terms, please refrain from using the website.</p>
<p><strong>1. Use of Content</strong></p>
<p>1.1 All content on this website, including text, images, videos, logos, and graphics, is the property of Nagar Nigam Ayodhya unless otherwise stated.<br /> 1.2 Content may not be copied, reproduced, or distributed for commercial purposes without prior written consent.</p>
<p><strong>2. User Responsibility</strong></p>
<p>2.1 Users must ensure that the information they provide on the website, such as for tax payments, complaint filing, or other services, is accurate and up-to-date.<br /> 2.2 Misuse of the website for fraudulent or unlawful purposes is strictly prohibited.</p>
<p><strong>3. Privacy Policy</strong></p>
<p>3.1 The personal information collected on this website will be handled in accordance with our <a href="https://chatgpt.com/c/677d03ff-4524-800d-bd45-5bfacb2464a5">Privacy Policy</a>.<br /> 3.2 The website may use cookies to improve user experience. By using the website, you consent to the use of cookies.</p>
<p><strong>4. Complaint Filing</strong></p>
<p>4.1 Complaints registered through the website will be addressed in a timely manner.<br /> 4.2 Nagar Nigam Ayodhya reserves the right to reject complaints that are irrelevant, offensive, or contain false information.</p>
<p><strong>5. Disclaimer of Liability</strong></p>
<p>5.1 While we strive to provide accurate and up-to-date information, Nagar Nigam Ayodhya does not guarantee the completeness, accuracy, or reliability of the website content.<br /> 5.2 The website may contain links to third-party websites. Nagar Nigam Ayodhya is not responsible for the content or practices of such external sites.</p>
<p><strong>6. Modifications</strong></p>
<p>6.1 Nagar Nigam Ayodhya reserves the right to update or modify these terms and conditions at any time without prior notice.<br /> 67.2 Continued use of the website implies acceptance of any changes to these terms.</p>
<p><strong>7. Governing Law</strong></p>
<p>7.1 These terms and conditions are governed by the laws of India.<br /> 7.2 Any disputes arising from the use of this website shall be subject to the jurisdiction of the courts in Ayodhya, Uttar Pradesh.</p>
<p>&nbsp;</p>
<p>By using this website, you acknowledge that you have read, understood, and agreed to these terms and conditions. If you have any questions, please contact Nagar Nigam Ayodhya at [contact information].</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <ImportLinks />
          </div>
        </div>
        <Footer />
      <FooterPre/>
      </div>
    </>
  );
};


export default TermCondition;