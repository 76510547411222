import React from "react";
import "./Heroslider.css";

const HerosliderImg = () => {
  const items = [
    { id: 1, name: "Item 1", imageUrl: "../Slider/slider5.jpg" },
    { id: 2, name: "Item 2", imageUrl: "../Slider/slider4.jpg" },
    { id: 3, name: "Item 3", imageUrl: "../Slider/slider1.jpg" },
    { id: 4, name: "Item 3", imageUrl: "../Slider/slider2.jpg" },
    { id: 5, name: "Item 3", imageUrl: "../Slider/slider3.jpg" },

  ];
  return (
    <div>
      <div
        id="carouselExampleAutoplaying"
        className="carousel slide"
        data-bs-ride="carousel"
      >
                <div className="carousel-inner"> 
          {items.map((item) => (
            <div className="carousel-item active" key={item.id}>
              <img
                src={item.imageUrl}
                alt={item.name}
                className="d-block w-100"
              />
            </div>
          ))}
        </div>
       {/* <div className="carousel-inner">
  {items.map((item, index) => (
    <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={item.id}>
      {index === 0 ? (
        <a href="https://ayodhyada.in/docs/202411/nov-not.pdf" target="_blank" rel="noopener noreferrer">
          <img
            src={item.imageUrl}
            alt={item.name}
            className="d-block w-100"
            style={{ height: "600px" }} // Adjust the height as needed
          />
        </a>
      ) : (
        <img
          src={item.imageUrl}
          alt={item.name}
          className="d-block w-100"
          style={{ height: "600px" }} // Adjust the height as needed
        />
      )}
    </div>
  ))}
</div>*/}

        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default HerosliderImg;
